
const testimonials = [
  {
    name: `Sheri Sanders`,
    title: `Simply Amazing!`,
    quote: `Chris is the only photographer that I will use for my listings. He has a great eye for real estate and is extremely detail oriented. When I get a listing, the first thing I think is...<br>Let me schedule an appointment with Chris`,
    stars: 5
  },
  {
    name: `Patricia Grainger`,
    title: `Chris Ventress`,
    quote: `Chris is very professional &amp; punctual. You get high quality photos and drone videos that are sure to please you and your clients. Professional photos are very important in marketing &amp; Chris takes the time to be sure they are very appealing without distorting. I feel that professional photos by Chris help get your listing sold faster!`,
    stars: 5
  },
  {
    name: `Jenny McCord`,
    title: `My Go-To Photographer!`,
    quote: `Chris is always extremely flexible and professional and the photos and dronework are what help get my listings sold quickly! His work is profoundly striking and I couldn’t ask for better quality!`,
    stars: 5
  },
];

export default testimonials;