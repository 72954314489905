import * as React from 'react'

const Companies = ({image1, image2, image3, image4, image5, image6, image7}) => {

  return (
    <div className="companiesContainer"
        data-sal="slide-up"
        data-sal-delay="1200"
        data-sal-easing="ease"
        data-sal-duration="1200"
    >
        <div className="companyItem">
            {image1}
        </div>
        <div className="companyItem">
            {image2}
        </div>
        <div className="companyItem">
            {image3}
        </div>
        <div className="companyItem">
            {image4}
        </div>
        <div className="companyItem">
            {image5}
        </div>
        <div className="companyItem">
            {image6}
        </div>
        <div className="companyItem">
            {image7}
        </div>
        
    </div>
  )
}

export default Companies