import * as React from 'react'
import { Link } from "gatsby"

const ExampleCard = ({title, text, image, icon, buttonText, to}) => {
  return (
        <div 
            className="exampleCard"
            data-sal="slide-up"
            data-sal-delay="1200"
            data-sal-easing="ease"
            data-sal-duration="1200"
            >
            
            
                <div className="exampleImage">
                    <Link to={to}>
                        {image}
                    </Link>
                </div>
                <div className="exampleContent">
                    <Link to={to}>
                        <div className="exampleTitle">
                            {title}
                        </div>
                        <div className="exampleText">
                            {text}
                        </div>
                        {/* <div className="icon centered">
                            <img src={icon} alt="Package" />
                        </div> */}
                    </Link>
                
                </div>
                <div className="exampleButtonDiv">
                    <Link to={to}>
                        <button className="exampleButton">
                                Examples
                        </button>
                    </Link>
                </div>
        </div> 
  )
}

export default ExampleCard