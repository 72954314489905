import * as React from 'react'
import * as testimonialCSS from './testimonialSlider.module.css'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'
// Import Swiper styles
import { Autoplay, Pagination, Navigation } from 'swiper'
import 'swiper/css/autoplay'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css'

const TestimonialSlider = ({ testimonials }) => {
  return (
    <div className="testimonialSlider">
        <Swiper
          modules={[Autoplay, Pagination, Navigation]}
          autoplay={{ delay: 8000 }}
          spaceBetween={50}
          slidesPerView={1}
          onSlideChange={() => console.log('slide change')}
          onSwiper={(swiper) => console.log(swiper)}
          loop={true} pagination={{
            "clickable": true
          }} navigation={true} className="mySwiper"
        >
             {testimonials.map((testimonial, i) => (
                <SwiperSlide key={i} className="testimonialSlide">
                    <div className="testimonialTitle">
                        <div dangerouslySetInnerHTML={{__html: testimonial.title}} />
                    </div>
                    <div className="testimonialQuote">
                        <div dangerouslySetInnerHTML={{__html: testimonial.quote}} />
                    </div>
                    <div className="testimonialName">
                        <div dangerouslySetInnerHTML={{__html: testimonial.name}} />
                    </div>
                    <div className="testimonialStars">
                    {/* {for (let i = 0; i < testimonial.stars; i++) {
                        text += cars[i] + "<br>";
                    }} */}
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
      </div>
  )
}

export default TestimonialSlider





