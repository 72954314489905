import * as React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const AreaCovered = (props) => {
  const data = useStaticQuery(graphql`
      query AreaCoveredQuery{
        file(relativePath: { eq: "images/service_area.jpeg" }) {
            childImageSharp {
              gatsbyImageData(
                width: 800
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            
          }
        }
      }
  `)
  console.log(data.file);
  const image = getImage(data.file);
  return (
    <div className="areaCoveredContainer">
      <div className="areaCoveredImage">
        <GatsbyImage image={image} alt="Area Covered"/>
      </div>
    </div>
  );
};

/*export const pageQuery = graphql`
  query {
    photos: allFile(
      sort: { fields: base, order: ASC }
      filter: { extension: { regex: "/(jpeg)/" } }
    ) {
      edges {
        node {
          id
          base
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              height: 400
              formats: AUTO
              width: 600
              quality: 70 # 50 by default
              transformOptions: { grayscale: true }
            )
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
*/
/* export const imageQuery = graphql`
  query {
    file(relativePath: { eq: "images/servicearea.jpeg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 400
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`; */

export default AreaCovered





